<template>
  <div class="widgetContainer">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="drawerBack" />
        <p class="title">
          Add Manager
        </p>
        <span class="el-icon-close" @click="drawerClose" />
      </div>
    </div>
    <div class="widgetContainer__body">
      <el-form @submit.native.prevent ref="searchForm" :model="searchForm">
        <el-form-item
          prop="searchKey"
          :rules="[
            { required: true, message: 'Please input email address', trigger: 'blur' },
            { type: 'email', message: 'Please input correct email address' }
          ]"
          class="is-no-asterisk"
          label="Search Person by Email">
          <el-input @input.native="toInvite = ''" type="email" placeholder="hello@shifl.com" v-model="searchForm.searchKey">
            <el-button type="default" slot="append" @click="addAsManager">
              <i class="el-icon-plus" />
            </el-button>
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="widgetContainer__footer--fixed" />
  </div>
</template>

<script>
export default {
  name: 'AddManager',
  data(){
    return {
      searchForm: {
        searchKey: ''
      }
    }
  },
  methods:{
    addAsManager(){
      this.$refs.searchForm.validate().then(() => {
        const loader = this.showLoader()
        this.$serverRequest.post('/v1/pilot',{
          email: this.searchForm.searchKey
        }).then(async ({data}) => {
          await this.$store.dispatch('dashboard/fetchManagers')
          this.$store.commit('dashboard/setManager',this.$store.getters['dashboard/getManagers'].find(manager => manager.id == data.data.manager.id));
          this.drawerReplace('EditManager');
        }).catch(e => this.apiErrorMessage(e))
          .finally(() => loader.close())
      }).catch(e => console.log(e))
    }
  }
}
</script>

<style scoped>

</style>